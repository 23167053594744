import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import $ from 'jquery'
window.jQuery = $
window.$ = $
import 'magnific-popup'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'moment'
import 'daterangepicker'
import apex_chart from 'apexcharts'
window.ApexCharts = apex_chart
import 'virtual-select-plugin/dist/virtual-select.min.js'

$(function() {
    VirtualSelect.init({
        ele: '.virtual-select',
        placeholder: '請選擇選項',
        noOptionsText: '查無結果',
        noSearchResultsText: '查無結果',
        selectAllText: '選擇全部',
        searchPlaceholderText: '輸入關鍵字篩選',
        optionsSelectedText: '個選項',
        allOptionsSelectedText: '所有選項',
        optionSelectedText: '個選項已選擇',
        silentInitialValueSet: true,
        multiple: true,
    });

    VirtualSelect.init({
        ele: '.place-virtual-select',
        placeholder: '請選擇選項',
        noOptionsText: '查無結果',
        noSearchResultsText: '查無結果',
        selectAllText: '選擇全部',
        searchPlaceholderText: '輸入關鍵字篩選',
        optionsSelectedText: '個選項',
        allOptionsSelectedText: '所有選項',
        optionSelectedText: '個選項已選擇',
        silentInitialValueSet: true,
        search: true,
        allowNewOption: true
    });

    $('input.date-range').daterangepicker({
        applyClass: "btn-main",
        autoUpdateInput: false,
        locale: {
            format: "YYYY-MM-DD",
            separator: " 至 ",
            applyLabel: "確定",
            cancelLabel: "取消",
            fromLabel: "從",
            toLabel: "至",
            customRangeLabel: "自訂",
            daysOfWeek: [
                "日",
                "一",
                "二",
                "三",
                "四",
                "五",
                "六"
            ],
            monthNames: [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月"
            ],
            firstDay: 0
        }
    }).on("apply.daterangepicker", (e, picker) => {
        $(picker.element).val(picker.startDate.format(picker.locale.format) + picker.locale.separator + picker.endDate.format(picker.locale.format)).change();
    });;

    // submenu click function
    if ($(window).width() < 1200) {
        $(".categories-collapse").on("click", function () {
            $(this).next().slideToggle();
            $(this).toggleClass("opened");
        });
    }

    // toggleopen click function
    $(".toggleopen").on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("opened");
        $(this).next().slideToggle();
    });

    $(".toggle-target").on("click", function (e) {
        e.preventDefault();
        var target = $(this).data("target");
        $(target).fadeIn();
        $(target)
            .find(".toggle-close")
            .on("click", function () {
                $(target).fadeOut();
            });
    });

    // set page height
    var topbottomh = $(".headerwrap").height() + $(".footer").height() * 2;
    $(".pagecont").css("min-height", "calc(100vh - " + topbottomh + "px)");

    $(".add-survey-question").on("click", function() {
        $.magnificPopup.open({
            items: {
                src: "#fail-box",
            },
            type: "inline",
        });
    });

    // box popup
    if($(".pop-modal").length) {
        $(".pop-modal").magnificPopup({
            type: "inline",
            showCloseBtn: false,
            enableEscapeKey: false,
            callbacks: {
                // open: function() {
                //     $.magnificPopup.instance.close = function() {
                //         console.log(this);
                //         // Do whatever else you need to do here
                //         var confirmed = confirm("關閉視窗後所有未儲存的資料都將遺失，您確定要關閉嗎？");
                //         if(!confirmed) {
                //             return;
                //         }
                
                //         // Call the original close method to close the popup
                //         $.magnificPopup.proto.close.call(this);
                //     };
                // },
                elementParse: function (item) {
                    var id = $(item.el).data("id");
                    if (id) {
                        $(item.src).find(".modal-footer .btn-main").html("儲存");
                    } else {
                        $(item.src).find(".modal-footer .btn-main").html("儲存");
                    }
                },
                close: function () {
                    var confirmed = confirm("關閉視窗後所有未儲存的資料都將遺失，您確定要關閉嗎？");
                    if(!confirmed) {
                        return;
                    }
                    var popInstance = this;
                    var curItem = popInstance.items.find((n) => n.src);
                    if (/fill-form/.test(curItem.src)) {
                        $(curItem.src).find("form").trigger("reset");
                    }
                    if (/warning-box/.test(curItem.src)) {
                        setTimeout(() => {
                            $(popInstance._lastFocusedEl).trigger("click");
                        }, 0);
                    }
                },
            },
        });
        if(window.location.hash == "#prefill-form") {
            $.magnificPopup.open({
                items: {
                    src: "#prefill-form",
                },
                type: "inline",
            });
        }
        if(window.location.hash == "#fill-form") {
            $.magnificPopup.open({
                items: {
                    src: "#fill-form",
                },
                type: "inline",
            });
        }
        if(window.location.hash.includes("#edit-form")) {
            $.magnificPopup.open({
                items: {
                    src: window.location.hash,
                },
                type: "inline",
            });
        }
        if(window.location.hash.includes("#show-form")) {
            $.magnificPopup.open({
                items: {
                    src: window.location.hash,
                },
                type: "inline",
            });
        }
        if(window.location.hash.includes("#audit-form")) {
            $.magnificPopup.open({
                items: {
                    src: window.location.hash,
                },
                type: "inline",
            });
        }
    }

    $(".close-modal").on("click", function () {
        $.magnificPopup.close();
    });

    $(".input-file input[type=file]").on("change", function (e) {
        if (e.target.files.length) {
            $(this).siblings("span").html(
                $('<img>', {
                    class: "upload-preview",
                    src: URL.createObjectURL(e.target.files[0])
                })
            )
        }
    });

    $("form[action]").on("reset", function () {
        if ($(this).find(".input-file")) {
            $(".input-file").find("span").html(`<i class="fas fa-plus"></i`);
        }
    });

    // $("form[action]").on("submit", function (e) {
    //     e.preventDefault();
    //     var form = $(this);
    //     var endpoint = form.attr("action");
    //     var method = form.attr("method");
    //     var formDataArray = form.serializeArray();
    //     var fileInput = form.find("input[type=file]");
    //     var inputCheck = formDataArray.every((field) => field.value);
    //     if (fileInput[0]) {
    //         inputCheck = fileInput[0].files.length;
    //     }

    //     if (!inputCheck) {
    //         $.magnificPopup.open({
    //             items: {
    //                 src: "#warning-box",
    //             },
    //             type: "inline",
    //         });
    //         return;
    //     }

    //     var formData = new FormData(this);

    //     $.ajax({
    //         type: method,
    //         url: endpoint,
    //         data: formData,
    //         processData: false,
    //         success: function (data) {
    //             const randomResult = Math.floor(Math.random() * 10) % 2;
    //             switch (randomResult) {
    //                 case 0:
    //                     $.magnificPopup.open({
    //                         items: {
    //                             src: "#success-box",
    //                         },
    //                         type: "inline",
    //                     });
    //                     form.trigger("reset");
    //                     break;
    //                 case 1:
    //                     $.magnificPopup.open({
    //                         items: {
    //                             src: "#fail-box",
    //                         },
    //                         type: "inline",
    //                     });
    //                     form.trigger("reset");
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         },
    //         error: function (data) {
    //             $.magnificPopup.open({
    //                 items: {
    //                     src: "#fail-box",
    //                 },
    //                 type: "inline",
    //             });
    //         },
    //     });
    // });

    // scroll functions
    var menuh = $("header").height();
    $(window).on("scroll", function () {
        if ($(window).scrollTop() > menuh) {
            $(".headerwrap").addClass("fixed");
            $(".headerwrap").css("padding-top", menuh);
        } else {
            $(".headerwrap").removeClass("fixed");
            $(".headerwrap").css("padding-top", 0);
        }
        if ($(window).scrollTop() > 300) {
            $(".scrollToTop").fadeIn();
        } else {
            $(".scrollToTop").fadeOut();
        }
    });
    $(".scrollToTop").on("click", function (e) {
        e.preventDefault();
        $("html, body").animate(
            {
                scrollTop: 0,
            },
            800
        );
        return false;
    });
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        $(tooltipTriggerEl).tooltip();
    });
});
